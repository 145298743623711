import styled, { css } from 'styled-components';
import { size, lineHeight } from '../settings/typography';

const fallbackFontSizes = (minFontSize: number, maxFontSize: number, minLineHeight: number, maxLineHeight: number) => css`
	@supports (-ms-ime-align:auto) {
		font-size: ${size[minFontSize]};
		line-height: ${lineHeight[minLineHeight]};

		@media screen and (min-width: 60em) {
			font-size: ${size[maxFontSize]};
			line-height: ${lineHeight[maxLineHeight]};
		}
	}
`

export const base = styled.p`
	font-size: ${size[16]};
	line-height: ${lineHeight[24]};
`

export const xs = styled.p`
	font-size: min(max(${size[12]}, 1.25vw), ${size[16]});
	line-height: min(max(${lineHeight[20]}, 1.875vw), ${lineHeight[24]});

	${fallbackFontSizes(12, 16, 20, 24)}
`

export const sm = styled.p`
	font-size: min(max(${size[16]}, 1vw), ${size[18]});
	line-height: min(max(${lineHeight[24]}, 1.875vw), ${lineHeight[28]});

${fallbackFontSizes(16, 18, 24, 28)}
`
export const smLabel = styled.label`
	font-size: min(max(${size[16]}, 1vw), ${size[18]});
	line-height: min(max(${lineHeight[24]}, 2.5vw), ${lineHeight[32]});

	${fallbackFontSizes(16, 20, 24, 32)}
`

export const md = styled.p`
	font-size: min(max(${size[20]}, 1.875vw), ${size[24]});
	line-height: min(max(${lineHeight[32]}, 2.8125vw), ${lineHeight[36]});

	${fallbackFontSizes(20, 24, 32, 36)}
`

export const mdPlus = styled.p`
	font-size: min(max(${size[16]}, 1.875vw), ${size[24]});
	line-height: min(max(${lineHeight[24]}, 2.8125vw), ${lineHeight[36]});

	${fallbackFontSizes(16, 24, 24, 36)}
`

export const big = styled.p`
	font-size: min(max(${size[20]}, 2.5vw), ${size[32]});
	line-height: min(max(${lineHeight[32]}, 3.75vw), ${lineHeight[48]});

	${fallbackFontSizes(20, 32, 32, 48)}
`

export const lg = styled.p`
	font-size: min(max(${size[24]}, 2.5vw), ${size[32]});
	line-height: min(max(${lineHeight[36]}, 3.75vw), ${lineHeight[48]});

	${fallbackFontSizes(24, 32, 36, 48)}
`

export const xl = styled.p`
	font-size: min(max(${size[24]}, 3.125vw), ${size[40]});
	line-height: min(max(${lineHeight[36]}, 4.375vw), ${lineHeight[56]});

	${fallbackFontSizes(24, 40, 36, 56)}
`

export const xxl = styled.p`
	font-size: min(max(${size[32]}, 3.335vw), ${size[48]});
	line-height: min(max(${lineHeight[48]}, 4.375vw), ${lineHeight[64]});

	${fallbackFontSizes(32, 48, 48, 64)}
`

export const xxxl = styled.p`
	font-size: min(max(${size[36]}, 3.335vw), ${size[64]});
	line-height: min(max(${lineHeight[40]}, 4.375vw), ${lineHeight[72]});

	${fallbackFontSizes(36, 64, 40, 72)}
`